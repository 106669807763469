
import {
  computed, defineComponent, onMounted, ref, toRefs, watch,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import type { Component } from 'vue';
import CountdownPlate from '@/components/ui/CountdownPlate';
import DialogComponent from '@/components/ui/DialogComponent';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import TTBreadcrumbs from '@/components/ui/TTLBreadcumbs';
import UpButton from '@/components/ui/UpButton.vue';
import { IBreadcrumb } from '@/domains/common';
import { QuizQuestionType } from '@/domains/quiz';
import { useQuizSession } from '@/domains/quiz/composables/useQuizSession';
import { useI18n } from '@/plugins/vue-i18n';
import { Names } from '@/plugins/vue-router';
import QuizQuestionHash from '@/components/quiz/hash/QuizQuestionHash.vue';
import QuizQuestionOpen from '@/components/quiz/open/QuizQuestionOpen.vue';
import QuizQuestionOrder from '@/components/quiz/order/QuizQuestionOrder.vue';
import QuizQuestionPoly from '@/components/quiz/poly/QuizQuestionPoly.vue';
import QuizQuestionLoader from '@/components/quiz/QuizQuestionLoader.vue';
import QuizPagination from '@/components/quiz/QuizPagination.vue';
import QuizQuestionHeader from '@/components/quiz/QuizQuestionHeader.vue';
import { useRef } from '@/composables';
import TestCountdown from '@/components/ui/TestCountdown/TestCountdown.vue';
import { Banner } from '@/types/banner';
import { useDi } from '@/plugins';
import { BasePage } from '@/components/ui/BasePage';

export default defineComponent({
  name: 'AssignmentQuizPlayer',

  components: {
    TTBackButton,
    UpButton,
    CountdownPlate,
    DialogComponent,
    TTBreadcrumbs,
    QuizPagination,
    QuizQuestionHeader,
    TestCountdown,
    BasePage,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },

    questionId: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const { playerSessionId, questionId } = toRefs(props);

    const { t } = useI18n();
    const router = useRouter();
    const di = useDi();

    const isLoading = ref<boolean>(false);

    const {
      isQuestionLoading,
      isAnswerSaving,
      isFirstQuestion,
      isLastQuestion,
      isTimeOver,
      isTestTimeLimited,
      isTestTimeExpired,
      isAnsweredQuestion,
      isQuestionAvailable,
      isUnansweredQuestionsExists,
      isPrevQuestionButtonVisible,
      isPrevQuestionButtonDisabled,
      isNextQuestionButtonVisible,
      isNextQuestionButtonDisabled,
      isPassingStrictMode,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,

      session,
      prevQuestion,
      nextQuestion,
      testTimeoutInSeconds,
      testStartedAt,

      finishSession,
      finishQuestion,
      fetchQuestion,
      skipQuestion,

      questions,
      currentQuestion,
      currentAnswer,
      updateAnswer,

      init,
    } = useQuizSession({ playerSessionId });

    watch(questionId, (questionIdValue) => {
      fetchQuestion({ questionId: questionIdValue });
    });

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-program-link',
      },
      {
        text: session.value?.name ?? '',
        'data-test-label': 'program-name-link',
        disabled: true,
      },
    ]);

    const questionComponent = computed<Component>(() => {
      switch (currentQuestion.value?.type) {
        case QuizQuestionType.HASH:
          return QuizQuestionHash;
        case QuizQuestionType.OPEN:
          return QuizQuestionOpen;
        case QuizQuestionType.ORDER:
          return QuizQuestionOrder;
        case QuizQuestionType.POLY:
          return QuizQuestionPoly;
        default:
          return QuizQuestionLoader;
      }
    });

    /* UI */
    const [completeQuizDialogRef, setCompleteQuizDialogRef] = useRef<Banner>();
    const [closeQuizDialogRef, setCloseQuizDialogRef] = useRef<Banner>();
    const [finishQuizDialogRef, setFinishQuizDialogRef] = useRef<Banner>();
    const [skipQuizDialogRef, setSkipQuizDialogRef] = useRef<Banner>();

    const shouldShowCloseDialog = computed(() => isTestTimeLimited.value && !isTestTimeExpired.value);
    const shouldShowSkipDialog = computed(() => isPassingStrictMode.value && !isAnsweredQuestion.value);
    const shouldShowCompleteDialog = computed(() => {
      if (isTestTimeExpired.value) {
        return false;
      }

      if (isLastQuestion.value) {
        return isPassingStrictMode.value;
      }

      return true;
    });

    const shouldShowFinishDialog = computed(() => {
      if (isTestTimeExpired.value) {
        return false;
      }

      if (isUnansweredQuestionsExists.value) {
        return true;
      }

      // if (!isSessionCompleted.value) {
      //   return true;
      // }

      return false;
    });

    const isFormDisabled = computed(() => !isQuestionAvailable.value);
    const isResultButtonVisible = computed(() => isTestTimeLimited.value && isTimeOver.value);

    const goToGateway = async () => {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_GATEWAY,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    const goToIntro = async () => {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_INTRO,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    const goToQuestion = async (params: { questionId?: number }) => {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_PLAYER,
        params: {
          playerSessionId: String(playerSessionId.value),
          questionId: String(params.questionId),
        },
      });
    };

    const goToResult = async () => {
      await router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_RESULT,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    const onAnswerUpdateHandler = async (answer: Record<string, any>) => {
      await updateAnswer({ answer });
    };

    const onSkipQuestionHandler = async () => {
      try {
        await skipQuestion();
      } catch (e: any) {
        // TODO: Обрабатывать не только ошибки сервера

        switch (e.response.status) {
          case 422:
          case 409:
            await goToResult();

            break;
          case 404:
            await goToGateway();

            break;
          default:
            di.notify.error({
              content: e.message,
            });
            break;
        }
      }
    };

    const finishQuiz = async () => {
      try {
        await finishSession();
      } catch (e: any) {
        // TODO: Обрабатывать не только ошибки сервера

        switch (e.response.status) {
          case 409: // Прохождение теста уже закончено
            await goToResult();
            break;
          case 404:
            di.notify.error({
              content: 'Не могу найти никаких данных о прохождении',
            });
            break;
          default:
            di.notify.error({
              content: e.message,
            });
            break;
        }
      }
    };

    const onCompleteQuizHandler = () => {
      /**
       * Если время не ограничено или ограничено, но не закончилось - завершаем тест
       */
      const success = async () => {
        if (!isSessionCompleted.value) {
          await finishQuiz();
        }

        await goToResult();
      };

      if (shouldShowCompleteDialog.value) {
        completeQuizDialogRef.value
          ?.open(true)
          .then((continueTest: boolean) => {
            if (continueTest) {
              completeQuizDialogRef.value?.close();
            } else {
              success();
            }
          })
          .catch(() => {
            completeQuizDialogRef.value?.close();
          });
      } else {
        success();
      }
    };

    const onFinishQuizHandler = () => {
      /**
       * Если время не ограничено или ограничено, но не закончилось - завершаем тест
       */
      const success = async () => {
        if (!isSessionCompleted.value) {
          await finishQuiz();
        }

        await goToResult();
      };

      if (shouldShowFinishDialog.value) {
        finishQuizDialogRef.value
          ?.open(true)
          .then((continueTest) => {
            if (continueTest) {
              finishQuizDialogRef.value?.close();
            } else {
              success();
            }
          })
          .catch(() => {
            finishQuizDialogRef.value?.close();
          });
      } else {
        success();
      }
    };

    const goToPrevQuestion = async () => {
      if (isFirstQuestion.value) return;

      await goToQuestion({
        questionId: prevQuestion.value?.id,
      });
    };

    const goToNextQuestion = async () => {
      if (isAnsweredQuestion.value) {
        try {
          await finishQuestion();
        } catch (e: any) {
          console.error(e);
        }
      } else if (isPassingStrictMode.value) {
        try {
          await onSkipQuestionHandler();
        } catch (e: any) {
          console.error(e);
        }
      }

      if (isLastQuestion.value) {
        onFinishQuizHandler();

        await goToResult();
      } else {
        await goToQuestion({
          questionId: nextQuestion.value?.id,
        });
      }
    };

    const onClickPrevQuestionHandler = async () => {
      if (isPassingStrictMode.value) return;

      if (isAnsweredQuestion.value) {
        try {
          await finishQuestion();
        } catch (e: any) {
          console.error(e);
        }
      }

      await goToPrevQuestion();
    };

    const onClickNextQuestionHandler = async () => {
      if (isTestTimeExpired.value) {
        await onFinishQuizHandler();
        return;
      }

      if (shouldShowSkipDialog.value) {
        skipQuizDialogRef.value
          ?.open(true)
          .then((continueTest) => {
            if (continueTest) {
              skipQuizDialogRef.value?.close();
            } else {
              goToNextQuestion();
            }
          })
          .catch(() => {
            skipQuizDialogRef.value?.close();
          });
      } else if (isLastQuestion.value) {
        onFinishQuizHandler();
      } else {
        await goToNextQuestion();
      }
    };

    const onClickQuestionListItemHandler = async (__questionId: number) => {
      if (isAnsweredQuestion.value) {
        try {
          await finishQuestion();
        } catch (e: any) {
          console.error(e);
        }
      }

      if (isTestTimeExpired.value) {
        await onFinishQuizHandler();
        return;
      }

      await goToQuestion({
        questionId: __questionId,
      });
    };

    const onClickCloseTestHandler = async () => {
      if (shouldShowCloseDialog.value) {
        closeQuizDialogRef.value
          ?.open(true)
          .then(async (continueTest) => {
            if (continueTest) {
              closeQuizDialogRef.value?.close();
            } else {
              await goToIntro();
            }
          })
          .catch(() => {
            closeQuizDialogRef.value?.close();
          });
      } else {
        await goToIntro();
      }
    };

    const onTimerFinishHandler = async () => {
      await finishQuiz();
    };

    const onClickShowResultButtonHandler = async () => {
      await goToResult();
    };

    const onAnsweredHandler = (isAnswered: boolean) => {
      isAnsweredQuestion.value = isAnswered;
    };

    onMounted(async () => {
      init({ questionId: questionId.value }).catch(async (e: any) => {
        const errorStatus = e.response?.status;
        if (![404, 422].includes(errorStatus)) return;

        await goToResult();

        if (errorStatus !== 422) {
          di.notify.error({
            content: 'Возникли проблемы при прохождении материала - сессия не найдена',
          });
        }
      });
    });

    return {
      t,

      isLoading,
      isQuestionLoading,
      isAnswerSaving,

      session,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
      breadcrumbs,

      questionComponent,

      questions,
      currentQuestion,
      currentAnswer,
      isFormDisabled,

      isPrevQuestionButtonVisible,
      isPrevQuestionButtonDisabled,
      isNextQuestionButtonVisible,
      isNextQuestionButtonDisabled,

      onClickCloseTestHandler,
      onClickQuestionListItemHandler,
      onAnswerUpdateHandler,
      onClickNextQuestionHandler,
      onClickPrevQuestionHandler,
      onTimerFinishHandler,
      onClickShowResultButtonHandler,
      onAnsweredHandler,
      onCompleteQuizHandler,

      testStartedAt,
      testTimeoutInSeconds,
      isPassingStrictMode,
      isLastQuestion,
      isTimeOver,
      isResultButtonVisible,

      setCloseQuizDialogRef,
      setSkipQuizDialogRef,
      setCompleteQuizDialogRef,
      setFinishQuizDialogRef,
    };
  },
});
