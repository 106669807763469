
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router/composables';
import goBack from '@/plugins/vue-router/guards/goBack';

export default defineComponent({
  name: 'TTBackButton',

  emits: ['click'],

  setup(_, { emit }) {
    const router = useRouter();

    const goBackHandler = () => {
      emit('click');

      goBack(router);
    };

    return {
      goBackHandler,
    };
  },
});
