
import {
  defineComponent, ref, toRefs, watch, onMounted, onUnmounted,
} from 'vue';
import { throttle } from '@/helpers';

export default defineComponent({
  name: 'UpButton',

  props: {
    visibleHeight: {
      type: Number,
      default: 150,
    },

    dataTestLabel: {
      type: String,
      default: '',
    },

    isAlwaysVisible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { isAlwaysVisible, visibleHeight } = toRefs(props);

    const isVisible = ref<boolean>(isAlwaysVisible.value);

    const scrollTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      emit('click');
    };

    const onScrollHandler = throttle(() => {
      // Получить высоту прокрутки страницы
      const top = document.scrollingElement?.scrollTop ?? 0;

      isVisible.value = top > visibleHeight.value;
    }, 250);

    const init = () => {
      // привязываем событие прокрутки страницы
      window.addEventListener('scroll', onScrollHandler);
    };

    const destroy = () => {
      // удаляем событие прокрутки страницы
      window.removeEventListener('scroll', onScrollHandler);
    };

    watch(isAlwaysVisible, (newValue) => {
      if (newValue) {
        isVisible.value = true;
        destroy();
      } else {
        isVisible.value = false;
        init();
      }
    });

    onMounted(() => {
      if (isAlwaysVisible.value) return;

      init();
    });

    onUnmounted(() => {
      destroy();
    });

    return {
      isVisible,
      scrollTop,
    };
  },
});
