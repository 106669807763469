
import { computed, defineComponent, toRefs } from 'vue';
import Loader from '@/components/ui/Loader.vue';

export default defineComponent({
  name: 'TestCountdownView',

  components: {
    Loader,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isWarning: {
      type: Boolean,
      required: true,
    },
    timeLeft: {
      type: Number,
      default: 0,
    },
    hasTime: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const { timeLeft, hasTime, isWarning } = toRefs(props);

    const timeLeftMinutes = computed<string>(() => {
      const minutes = Math.floor(timeLeft.value / 60);
      return minutes < 10 ? `0${minutes}` : `${minutes}`;
    });

    const timeLeftSeconds = computed<string>(() => {
      const seconds = Math.floor(timeLeft.value % 60);
      return seconds < 10 ? `0${seconds}` : `${seconds}`;
    });

    const isTimeOver = computed<boolean>(() => timeLeft.value === 0);

    const iconClasses = computed(() => {
      if (!hasTime.value) {
        return ['tt-light-mono-46--text'];
      }

      if (isTimeOver.value) {
        return ['tt-light-red--text'];
      }

      if (isWarning.value) {
        return ['tt-light-red--text'];
      }

      return ['tt-light-mono-100--text'];
    });

    const timerClasses = computed(() => {
      if (isTimeOver.value) {
        return ['tt-light-red--text'];
      }

      if (isWarning.value) {
        return ['tt-light-red--text'];
      }

      return ['tt-light-mono-100--text'];
    });

    const captionClasses = computed(() => {
      if (!hasTime.value) {
        return ['tt-light-mono-46--text'];
      }

      if (isTimeOver.value) {
        return ['tt-light-red--text'];
      }

      return ['tt-light-mono-46--text'];
    });

    return {
      isTimeOver,

      timeLeftMinutes,
      timeLeftSeconds,

      iconClasses,
      timerClasses,
      captionClasses,
    };
  },
});
