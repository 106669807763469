import store from '@/plugins/vuex';

const namespace = 'pageHistory';

const goBack = (router) => {
  const prevPage = store.getters[`${namespace}/previewPage`];

  if (prevPage) {
    /**
     * Есть проблема с тем что pageHistory сохраняет историю не смотря на `router.replace`.
     * На текущий момент нет правильного решения, поэтому убираем логику связанную с
     * возвратом назад по искусственной истории и оставляем браузерную историю.
     *
     * @hash 6d7c03763b6d94319e5ca03dc78bbc99f7277638
     * @url https://burning-heart.atlassian.net/browse/AL-264
     * */
    router.go(-1);
  }
};

export default goBack;
