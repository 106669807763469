
import {
  computed, defineComponent, PropType, toRefs, onMounted, onUnmounted,
} from 'vue';
import { useAlarm } from '@/composables/useAlarm';
import { useI18n } from '@/plugins/vue-i18n';
import TestCountdownView from './TestCountdownView.vue';

export default defineComponent({
  name: 'TestCountdown',

  components: {
    TestCountdownView,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    startedAt: {
      type: String as PropType<DateTimeISO8601>,
      required: true,
    },

    maxSecondsCount: {
      type: Number,
      default: 0,
    },

    threshold: {
      type: Number,
      default: 0.1,
    },
  },

  emits: [
    'paused',
    'stopped',
    'finished',
  ],

  setup(props, { emit }) {
    const { startedAt, maxSecondsCount, threshold } = toRefs(props);

    const { t } = useI18n();

    const {
      timeLeft, stopTimer, startTimer,
    } = useAlarm({
      startedAt,
      maxSecondsCount,
      autostart: true,
    });

    const hasTime = computed(() => {
      return maxSecondsCount.value > 0;
    });

    const isThresholdOvercome = computed(
      () => (timeLeft.value
        ? maxSecondsCount.value * threshold.value > timeLeft.value
        : false
      ),
    );

    onMounted(() => {
      startTimer()
        .then(() => {
          emit('finished');
        })
        .catch(() => {
          emit('stopped');
        });
    });

    onUnmounted(() => {
      stopTimer();
    });

    return {
      t,
      hasTime,
      timeLeft,
      isThresholdOvercome,
    };
  },
});
