
import {
  defineComponent, PropType, computed, toRefs,
} from 'vue';
import { IBreadcrumb } from '@/domains/common';
import { isSlotExists } from '@/helpers/isSlotExists';

import UpButton from '../UpButton.vue';
import TTBackButton from '../TTBackButton.vue';

export default defineComponent({
  name: 'BasePage',

  components: { UpButton, TTBackButton },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: {
      type: Array as PropType<IBreadcrumb[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },

  emits: [
    'click:back',
    'click:up',
  ],

  setup(props, { emit, slots }) {
    const { breadcrumbs, title } = toRefs(props);

    const hasBreadcrumbs = computed(() => {
      return breadcrumbs.value.length > 0 || isSlotExists(slots.breadcrumbs);
    });

    const hasHeader = computed(() => {
      return title.value.length > 0 || isSlotExists(slots.header);
    });

    const hasFooter = computed(() => {
      return isSlotExists(slots.footer);
    });

    const onClickBackButtonHandler = () => {
      emit('click:back');
    };

    const onClickUpButtonHandler = () => {
      emit('click:up');
    };

    return {
      hasBreadcrumbs,
      hasHeader,
      hasFooter,
      onClickBackButtonHandler,
      onClickUpButtonHandler,
    };
  },
});
